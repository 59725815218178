const style = (theme) => {
  const output = {
    component: {
      position: 'relative',
      width: theme.header.burger.wrap,
      height: theme.header.burger.height,
      margin: '0 auto',
      // '&.navopen $button': {
      //   transitionDelay: '0.4s',
      //   background: theme.colors[5],
      // },
      // '&.navopen $line': {
      //   // transitionDelay: '0.4s',
      //   background: theme.colors[4],
      // },
    },
    button: {
      display: 'block',
      position: 'relative',
      top: 0,
      left: 0,
      border: 0,
      background: theme.colors[4],
      outline: 'none',
      cursor: 'pointer',
      width: '100%',
      height: theme.header.burger.height,
      margin: 0,
      padding: 0,
      boxSizing: 'content-box',
      // transition: 'all 0.5s ease-in-out 0.7s',
      borderRadius: 19,
    },
    wrapper: {
      display: 'block',
      position: 'relative',
      margin: '0 auto',
      width: '100%',
      height: '100%',
      pointerEvents: 'auto',
    },
    line: {
      display: 'block',
      position: 'absolute',
      width: 12,
      height: '1px',
      background: theme.colors[3],
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%)',
      transformOrigin: 'center center',
      marginTop: '-4px',
      '&:nth-child(3)': {
        marginTop: '4px',
      },
    },
    lineCenter: {
      marginTop: 0,
    },
    lineSmall: {},
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    component: {
      // height: theme.headerMobile.burger.wrap,
      // width: theme.headerMobile.burger.wrap,
      // background: theme.colors[0],
    },
  }
  return output
}

export { style }
