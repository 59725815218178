export default {
  header: {
    top: 0,
    height: 130,
    heightCompact: 78,
    heightMobile: 62,
    heightSub: 48,
    spacing: ['46px', '60px'],
    spacingMobile: ['30px', '30px'],
    logo: {
      height: 15,
      size: 15,
    },
    burger: {
      wrap: 74,
      height: '38px',
    },
  },
  headerMobile: {
    logo: {
      height: 116,
      width: 116,
    },
    burger: {
      wrap: 74,
      height: '38px',
    },
  },
}
