import { useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const useResizeObserver = ({
  ref = null,
  callback = () => {},
}) => {
  const observerRef = useRef()

  useEffect(() => {
    if (ref) {
      observerRef.current = new ResizeObserver(() => {
        if (ref && callback) callback()
      })
      observerRef.current.observe(ref)
    }
    return () => {
      if (ref) observerRef.current.disconnect(ref)
    }
  }, [ref])

  /*------------------------------
  Return values
  ------------------------------*/
  return null
}

export default useResizeObserver
