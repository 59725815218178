/* eslint-disable consistent-return */
/* https://github.com/studio-freight/satus/tree/main/components/scrollbar */
import { useEffect, useRef, useState } from 'react'
import { useWindowSize, useMeasure } from 'react-use'
import injectSheet from 'react-jss'
import { clamp, mapRange } from '@/utils/math'
import useScroll from '@/hooks/useScroll'
import useScrollStore from '@/store/scroll'
import style from './style'

function Scrollbar({ classes }) {
  const thumb = useRef()
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const { lenis } = useScrollStore()
  const [innerMeasureRef, { height: innerHeight }] = useMeasure()
  const [thumbMeasureRef, { height: thumbHeight }] = useMeasure()

  useScroll(({ scroll, limit }) => {
    const progress = scroll / limit

    thumb.current.style.transform = `translate3d(0, ${progress * (innerHeight - thumbHeight)}px, 0)`
  })

  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (!clicked) return

    function onPointerMove(e) {
      e.preventDefault()

      const offset = (windowHeight - innerHeight) / 2
      const y = mapRange(
        0,
        windowHeight,
        e.clientY,
        -offset,
        innerHeight + offset,
      )

      const progress = clamp(0, y / innerHeight, 1)
      const newPos = lenis.limit * progress

      lenis.direction === 'vertical' // eslint-disable-line
        ? window.scrollTo(0, newPos)
        : window.scrollTo(newPos, 0)
    }

    function onPointerUp() {
      setClicked(false)
    }

    window.addEventListener('pointermove', onPointerMove, false)
    window.addEventListener('pointerup', onPointerUp, false)

    return () => {
      window.removeEventListener('pointermove', onPointerMove, false)
      window.removeEventListener('pointerup', onPointerUp, false)
    }
  }, [clicked, windowHeight, windowWidth, lenis])

  return (
    <div className={classes.scrollbar}>
      <div ref={innerMeasureRef} className={classes.inner}>
        <div
          className={classes.thumb}
          ref={(node) => {
            thumb.current = node
            thumbMeasureRef(node)
          }}
          onPointerDown={() => setClicked(true)}
        />
      </div>
    </div>
  )
}

export default injectSheet(style)(Scrollbar)
