import { Component } from 'react'
import injectSheet from 'react-jss'
import { TimelineMax, Expo, Power2 } from 'gsap'
import { style } from './style'

class BtnBurger extends Component {
  /*------------------------------
  Will Mount
  ------------------------------*/
  UNSAFE_componentWillMount() {
    this.setState({
      isNavOpen: false,
    })
    this.mouseEnter = this.mouseEnter.bind(this)
    this.mouseLeave = this.mouseLeave.bind(this)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  componentDidMount() {
    const { classes } = this.props
    const $this = this.node
    if (!this.props.forceOpen) {
      // TweenMax.set($this, { visibility: 'hidden' })
      this.timelineopacity = new TimelineMax({ paused: true })
      this.timelineopacity
        .to($this, 3, { autoAlpha: 0, ease: Expo.easeInOut })
    }
    this.$ = {
      root: this.node,
      lines: this.node.getElementsByClassName(classes.line),
      lineCenter: this.node.getElementsByClassName(classes.lineCenter),
      lineSmall: this.node.getElementsByClassName(classes.lineSmall),
      button: this.node.getElementsByClassName(classes.button),
    }
    // this.opt = {
    //   a: {
    //     ease: Expo.easeOut,
    //     t: 1.82,
    //     tOut: 1.5,
    //     d: CONFIG.LOADER_HIDE_TIME + 0.3,
    //   },
    // }
    // if (this.props.show) {
    //   TweenMax.to(this.$.wrapperLabel, this.opt.a.t, { y: '0%', delay: this.opt.a.d, ease: this.opt.a.ease, force3D: true })
    // }
    this.initTimeline()
  }

  /*------------------------------
  Did Update
  ------------------------------*/
  componentDidUpdate(prevProps, prevState) {
    const { show, layout, open } = this.props

    if (show !== prevProps.show) {
      if (show) {
        // TweenMax.to(this.$.wrapperLabel, this.opt.a.t, { y: '0%', delay: this.opt.a.d, ease: this.opt.a.ease, force3D: true })
      }
    }
    if (layout !== prevProps.layout) {
      this.updateLayout()
    }
    if (this.state.mouseEnter !== prevState.mouseEnter) {
      if (this.state.mouseEnter) {
        this.tweenMouseEnter()
      } else {
        this.tweenMouseLeave()
      }
    }

    if (!this.state.isNavOpen && !this.state.mouseEnter) {
      this.timeline.pause().reverse()
    }

    if (open !== prevProps.open) {
      if (this.props.open) {
        this.updateStateNav(true)
      } else {
        this.updateStateNav(false)
      }
    }
  }

  /*------------------------------
  Handle Click
  ------------------------------*/
  handleClick() {
    if (this.props.status !== 'hold') {
      if (this.state.isNavOpen) {
        this.timeline.pause().reverse()
        if (!this.props.forceOpen && !this.props.forceClose) {
          this.props.handleClick(false)
        }
      } else {
        if (!this.props.forceOpen && !this.props.forceClose) {
          this.props.handleClick(true)
        }
        this.timeline.pause().play()
        this.timelineHover.pause()
      }
    } else {
      this.props.setTimelineStatus('end')
    }
  }

  /*------------------------------
  Update State Nav
  ------------------------------*/
  updateStateNav(value) {
    this.setState({
      isNavOpen: value,
    })
  }

  /*------------------------------
  Init Timeline
  ------------------------------*/
  initTimeline() {
    const { lineSmall, lineCenter, button, lines } = this.$

    this.timeline = new TimelineMax({
      paused: true,
      onComplete: () => {

      },
      onReverseComplete: () => {
        // if (!this.props.forceOpen && !this.props.forceClose) {
        //   this.props.handleClick(false)
        // }
      },
    })
    this.timeline
      // .to(lineSmall, 0.2, { width: '70%', force3D: true }, 0)
      .to(button, 0.4, { backgroundColor: this.props.theme.colors[5], delay: 0.2 }, 0)
      .to(lines, 0.4, { backgroundColor: this.props.theme.colors[4], delay: 0.2 }, 0)
      .fromTo(lineSmall[0], 0.4, { y: '0px' }, { y: '4px', ease: Power2.easeOut, force3D: true }, 0)
      .fromTo(lineSmall[0], 0.4, { rotationZ: 0 }, { rotationZ: 45, ease: Power2.easeOut, force3D: true }, 0.2)
      .fromTo(lineCenter, 0.2, { opacity: 1 }, { opacity: 0 }, 0.0)
      .fromTo(lineSmall[1], 0.4, { y: '0px' }, { y: '-4px', ease: Power2.easeOut, force3D: true }, 0)
      .fromTo(lineSmall[1], 0.4, { rotationZ: 0 }, { rotationZ: -45, ease: Power2.easeOut, force3D: true }, 0.2)
      // .to(lineSmall[0], 0.25, { rotation: -63, ease: Power2.easeInOut, force3D: true }, 0)
      // .to(lineSmall[1], 0.25, { rotation: 63, ease: Power2.easeInOut, force3D: true }, 0)
      // .to(lineCenter, 0.25, { autoAlpha: 0, ease: Power2.easeInOut, force3D: true }, 0)

    this.timelineHover = new TimelineMax({ paused: true })
    this.timelineHover
      .staggerTo(lineCenter, 0.2, { width: 24, x: 0 }, 0.02, 0)
  }

  /*------------------------------
  Tween Mouse Enter
  ------------------------------*/
  tweenMouseEnter() {
    if (this.props.mediaQueryKey > 2) {
      this.timelineHover.play()
    }
  }

  /*------------------------------
  Tween Mouse Leave
  ------------------------------*/
  tweenMouseLeave() {
    if (this.props.mediaQueryKey > 2) {
      if (!this.state.isNavOpen) {
        this.timelineHover.pause().reverse()
      }
    }
  }

  /*------------------------------
  Update Layout
  ------------------------------*/
  updateLayout() {
    this.setState({
      layout: this.props.layout,
    })
  }

  /*------------------------------
  Mouse Enter
  ------------------------------*/
  mouseEnter() {
    this.setState({
      mouseEnter: true,
    })
    this.props.setHover(true)
  }

  /*------------------------------
  Mouse Leave
  ------------------------------*/
  mouseLeave() {
    this.setState({
      mouseEnter: false,
    })
    this.props.setHover(false)
  }

  /*------------------------------
  Render
  ------------------------------*/
  render() {
    const { classes } = this.props

    return (
      <div className={`${classes.component} ${this.props.className} ${this.state.layout} ${this.state.isNavOpen ? 'navopen' : ''}`} ref={(node) => this.node = node}>
        <div className={classes.wrapper}>
          <button
            className={classes.button}
            onClick={(e) => this.handleClick(e)}
            onMouseEnter={this.mouseEnter}
            onMouseLeave={this.mouseLeave}
          >
            <div className={`${classes.line} ${classes.lineSmall}`} />
            <div className={`${classes.line} ${classes.lineCenter}`} />
            <div className={`${classes.line} ${classes.lineSmall}`} />
          </button>
        </div>
      </div>
    )
  }
}

BtnBurger.defaultProps = {
  colorLine: '#000',
  trigger: 'loaded',
  time: 2,
  delay: 0,
}

export default injectSheet(style)(BtnBurger)
