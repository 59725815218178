import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { SplitText } from 'gsap/dist/SplitText'
import { CustomEase } from 'gsap/dist/CustomEase'
import { Observer } from 'gsap/dist/Observer'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(SplitText, ScrollTrigger, CustomEase, Observer)
  CustomEase.create('outQuint', '0.25, 1, 0.35, 1')
}
