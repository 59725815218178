export default {
  overlay: 101,
  subheader: 101,
  spinner: 102,
  nav: 102,
  header: 103,
  modal: 103,
  scrollbar: 104,
  transition: 104,
  cursor: 105,
  cookiebar: 300,
  debug: 499,
  loader: 500,
  noise: 501,
}
