import { useCallback, useRef, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Header from '@/components/Header'
import Portal from '@/components/__commons/Portal'
import Footer from '@/components/Footer'
import PageTransition from '@/components/__commons/PageTransition'
import Scrollbar from '@/components/__new/Scrollbar'
import Nav from '@/components/__new/Nav'
import SVGSprite from '@/components/__layout/SVGSprite'
import Modal from '@/components/Modal'
import ModalForm from '@/components/__modal/ModalForm'
import useResizeObserver from '@/hooks/useResizeObserver'
import { theme } from '@/styles/style'
import global from '@/styles/global'
import useScrollStore from '@/store/scroll'
import actions from '@/actions'

function Layout({
  children,
  classes,
}) {
  const $root = useRef()
  const lenis = useScrollStore((state) => state.lenis)
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isLoading, strings, modalText, modalImage, modalOpen } = useSelector((state) => ({
    isLoading: !state.loading.ready,
    strings: state.language.string,
    modalText: state.modal.text,
    modalImage: state.modal.image,
    modalOpen: state.modal.open,
  }), shallowEqual)

  const dispatch = useDispatch()
  const setModalOpen = useCallback((index) => dispatch(actions.modal.setOpen(index)), [dispatch])
  const setModalImage = useCallback((index) => dispatch(actions.modal.setImage(index)), [dispatch])
  const setModalText = useCallback((index) => dispatch(actions.modal.setText(index)), [dispatch])

  useEffect(() => {
    if (!isLoading && lenis) lenis.start()
  }, [isLoading])

  useResizeObserver({
    ref: $root.current,
    callback: () => ScrollTrigger.refresh(),
  })

  return (
    <div className={`${classes.root} content-root`}>
      <div
        className="wrapper"
        ref={$root}
      >
        {children}
        <Footer
          strings={strings}
          show={true}
          theme={theme}
          data={{
            title: strings.contact_society,
            text: strings.contact_address,
            imgRight: strings.footer_right,
          }}
        />
      </div>
      <SVGSprite />
      <PageTransition isLoading={isLoading} />
      <Portal className="header-root">
        <Header />
      </Portal>
      <Portal className="nav-root">
        <Nav />
      </Portal>
      <Portal className="scrollbar-root">
        <Scrollbar />
      </Portal>
      <Modal
        image={modalImage}
        text={modalText}
        open={modalOpen}
        setOpen={setModalOpen}
        setImage={setModalImage}
        setText={setModalText}
        btnColor={theme.colors[6]}
        labelClose={strings.generic_close}
        theme={theme}
      />
      <Portal className={`modal-form-root-${strings.send_configuration_form_id}`}>
        <ModalForm id={strings.send_configuration_form_id} />
      </Portal>
      <Portal className={`modal-form-root-${strings.save_configuration_form_id}`}>
        <ModalForm id={strings.save_configuration_form_id} />
      </Portal>
    </div>
  )
}

export default injectSheet(global)(Layout)
