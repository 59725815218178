import * as types from '../actions/types'

const initialState = {
  pages: [],
  posts: [],
  media: [],
  archive: [],
  taxonomies: [],
  forms: [],
  status_form: {
    status: '',
    message: '',
  },
  results: {},
  show: false,
  transition: true,
  negative: false,
  layout: 'default',
  current: '',
  currentType: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGE_ADD_CONTENT:
      state = {
        ...state,
        pages: { ...state.pages, ...action.content },
      }
      break
    case types.PAGE_ADD_POST:
      state = {
        ...state,
        posts: { ...state.posts, ...action.content },
      }
      break
    case types.PAGE_LIST_CONTENT:
      state = {
        ...state,
        archive: { ...state.archive, ...action.list },
      }
      break
    case types.PAGE_LIST_TAXONOMIES:
      state = {
        ...state,
        taxonomies: { ...state.taxonomies, ...action.list },
      }
      break
    case types.PAGE_ADD_MEDIA:
      state = {
        ...state,
        media: { ...state.media, ...action.data },
      }
      break
    case types.PAGE_ADD_FORM:
      state = {
        ...state,
        forms: { ...state.forms, ...action.content },
      }
      break
    case types.PAGE_STATUS_FORM:
      state = {
        ...state,
        status_form: action.result,
      }
      break
    case types.PAGE_SET_TRANSITION:
      state = {
        ...state,
        transition: action.setTransition,
      }
      break
    case types.PAGE_SET_LAYOUT:
      state = {
        ...state,
        layout: action.setLayout,
      }
      break
    case types.PAGE_SET_SHOW:
      state = {
        ...state,
        show: action.setShow,
      }
      break
    case types.PAGE_SET_CURRENT:
      state = {
        ...state,
        current: action.setShow,
        currentType: action.setType,
      }
      break
    case types.GET_SEARCH:
      state = {
        ...state,
        results: { ...state.results, ...action.content },
      }
      break
    case types.SET_NEGATIVE:
      state = {
        ...state,
        negative: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
