import language from '@/reducers/language'
import layer from '@/reducers/layer'
import loading from '@/reducers/loading'
import locale from '@/reducers/locale'
import mediaQuery from '@/reducers/mediaQuery'
import modal from '@/reducers/modal'
import nav from '@/reducers/nav'
import page from '@/reducers/page'
import pointer from '@/reducers/pointer'
import routing from '@/reducers/routing'

export default {
  language,
  layer,
  loading,
  locale,
  mediaQuery,
  modal,
  nav,
  page,
  pointer,
  routing,
}
