import { RECEIVE_NAV, NAV_SET_OPEN, NAV_SET_COMPACT, NAV_SET_BACKGROUND } from '@/actions/types'
import { camelCase } from '@/utils/strings'

const initialState = {
  open: false,
  compact: false,
  background: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NAV_SET_OPEN:
      state = {
        ...state,
        open: action.setOpen,
      }
      break
    case NAV_SET_COMPACT:
      state = {
        ...state,
        compact: action.setCompact,
      }
      break
    case NAV_SET_BACKGROUND:
      state = {
        ...state,
        background: action.setBackground,
      }
      break
    case RECEIVE_NAV:
      state = {
        ...state,
        [camelCase(action.key)]: action.payload,
      }
      break
    default:
      return state
  }
  return state
}
