import { useRef, useState, useContext, useEffect } from 'react'
import injectSheet from 'react-jss'
import gsap from 'gsap'
import { LoaderEngineContext } from '@/components/LoaderEngine'
import Logo from '@/components/Logo'
import style from './style'

function Loader({
  classes,
  theme,
}) {
  const { ready, firstLoad } = useContext(LoaderEngineContext)
  const [$root, setRoot] = useState()
  const $logoSpan = useRef()
  const q = gsap.utils.selector($logoSpan)

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    gsap.to(q('span'), {
      y: '110%',
      delay: 0.5,
      duration: 0.3,
      stagger: 0.1,
      ease: 'expo.in',
    })
  }
  /*------------------------------
  DeleteMe
  ------------------------------*/
  const deleteMe = () => {
    gsap.set($root, { display: 'none' })
    gsap.set(q('span'), { display: 'none' })
  }

  /*------------------------------
  HideLoader
  ------------------------------*/
  const hideLoader = () => {
    gsap.set(q('span'), {
      y: '-110%',
    })
    const timeline = gsap.timeline({ onComplete: deleteMe })
    timeline
      .to($root, {
        y: '110%',
        delay: 0.1,
        duration: 1.4,
        ease: 'expo.inOut',
      }, 0)
      .to(q('span'), {
        y: '0%',
        delay: 0.1,
        stagger: 0.08,
        duration: 0.6,
        ease: 'expo.out',
      }, 0)
  }

  /*------------------------------
  useEffect --> ready
  ------------------------------*/
  useEffect(() => {
    if (firstLoad && ready) {
      hideLoader()
    }
  }, [firstLoad, ready])

  useEffect(() => {
    init()
  }, [])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classes.root}
      ref={setRoot}
    >
      <div className={classes.logo}>
        <Logo color={theme.colors[6]} />
        <div ref={$logoSpan}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  )
}

export default injectSheet(style)(Loader)
