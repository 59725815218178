import { MEDIA_QUERIES_SET, MEDIA_QUERIES_SET_KEY } from '@/actions/types'

export function setQuery(state) {
  return {
    type: MEDIA_QUERIES_SET,
    setQuery: state,
  }
}

export function setQueryKey(state) {
  return {
    type: MEDIA_QUERIES_SET_KEY,
    setQueryKey: state,
  }
}
