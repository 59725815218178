/* eslint-disable react/no-array-index-key */
import { useMemo, useRef, useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import style from './style'

function LangNav({
  className,
  classes,
  hide,
  data,
}) {
  const activeLang = useMemo(() => window.location.pathname.split('/')[1], [])
  const [visible, setVisible] = useState(false)
  const $button = useRef(null)

  const handleClick = () => {
    setVisible(!visible)
  }

  const handleOutsideClick = (event) => {
    if ($button.current && !$button.current.contains(event.target)) {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [visible])

  return (
    <div className={clsx({
      [classes.root]: true,
      [classes.hide]: hide,
      [className]: true,
      'lang-nav': true,
    })}
    >
      <div
        className={clsx({
          [classes.item]: true,
          'lang-active': true,
        })}
      >
        <button ref={$button} onClick={handleClick} className={classes.parentItem}>{activeLang}</button>
      </div>
      <ul className={clsx({
        [classes.popup]: true,
        [classes.visible]: visible,
      })}
      >
        { data.filter((obj) => obj !== activeLang).map((obj, index) => {
          return (
            <li
              key={`key-${index}`}
              className={classes.item}
            >
              <a href={`/${obj}`} className={classes.parentItem}>{obj}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default injectSheet(style)(LangNav)
