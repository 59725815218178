const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      pointerEvents: 'auto',
    },
    link: {
      display: 'none',
    },
    input: {
      width: '100%',
      border: 'none',
      background: theme.colors[7],
      height: 38,
      borderRadius: 19,
      paddingLeft: 43,
      paddingRight: 17,
      pointerEvents: 'auto',
    },
    submit: {
      cursor: 'pointer',
      position: 'absolute',
      top: 1,
      left: 5,
      zIndex: 1,
      width: 38,
      height: 38,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'auto',
      '& svg': {
        width: 12,
        height: 12,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    input: {
      fontSize: 16,
    },
  }

  return output
}
export default style
