import { mainCacheAxios as axios } from '@/base/axios'
import { API } from '@/constants'
import * as types from './types'

export function getString() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.STRINGS}?lang=${getState().locale.currentLanguage}`)
        .then((response) => {
          dispatch({
            type: types.STRING_GET_ALL,
            data: response.data,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
