import { MODAL_SET_OPEN, MODAL_SET_IMAGE, MODAL_SET_TEXT, MODAL_SET_NODE } from '@/actions/types'

export function setOpen(bool) {
  return {
    type: MODAL_SET_OPEN,
    setOpen: bool,
  }
}

export function setImage(image) {
  return {
    type: MODAL_SET_IMAGE,
    setImage: image,
  }
}

export function setText(image) {
  return {
    type: MODAL_SET_TEXT,
    setText: image,
  }
}

export function setNode(node) {
  return {
    type: MODAL_SET_NODE,
    setNode: node,
  }
}
