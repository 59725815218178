import { createUseStyles } from 'react-jss'

const style = (theme) => ({
  root: {
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: theme.zindex.spinner,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: theme.colors[5],
    transition: `opacity 1s ${theme.easings['power3.out']}`,
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  visible: {
    opacity: 1,
    transition: `opacity 0.2s ${theme.easings['power3.out']}`,
  },
  spinner: {
    width: 30,
    height: 30,
    top: 'calc(50% - 15px)',
    left: 'calc(50% - 15px)',
  },
  spinnerLoader: {
    stroke: theme.colors[4],
    width: '100%',
    height: '100%',
    transition: `all 1s ${theme.easings['power3.out']}`,
    animation: '$rotate 2s linear infinite',
  },
  spinnerPath: {
    strokeLinecap: 'round',
    animation: '$dash 1.5s ease-in-out infinite',
  },
  spinnerThemeInverse: {
    '& $spinnerLoader': {
      stroke: theme.getRgba(theme.colors[3], 1),
    },
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes dash': {
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-35',
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-124',
    },
  },
})

export const useStyles = createUseStyles(style)
