import { useEffect } from 'react'

const setVh = function () {
  const svh = document.documentElement.clientHeight * 0.01
  document.documentElement.style.setProperty('--vh-fixed', (`${svh}px`))
  const dvh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', (`${dvh}px`))

  if (document.body) {
    const fixed = document.createElement('div')
    fixed.style.width = '1px'
    fixed.style.height = '100vh'
    fixed.style.position = 'fixed'
    fixed.style.left = '0'
    fixed.style.top = '0'
    fixed.style.bottom = '0'
    fixed.style.visibility = 'hidden'

    document.body.appendChild(fixed)

    const fixedHeight = fixed.clientHeight

    fixed.remove()

    const lvh = fixedHeight * 0.01

    document.documentElement.style.setProperty('--vh-fixed-large', (`${lvh}px`))
  }
}

const useViewport = () => {
  useEffect(() => {
    // SSR support
    if (typeof window === 'undefined') {
      return
    }
    setVh()
    window.addEventListener('resize', setVh)
  }, [])

  return null
}

export default useViewport
