import { RECEIVE_LOCALES, SET_LANGUAGE } from '@/actions/types'

const initialState = {
  currentLanguage: null,
  currentLanguageIso3: null,
  languages: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOCALES:
      state = {
        ...state,
        languages: action.payload,
      }
      break
    case SET_LANGUAGE:
      state = {
        ...state,
        currentLanguage: action.iso2,
        currentLanguageIso3: action.iso3,
      }
      break
    default:
      return state
  }
  return state
}
