import * as language from '@/actions/language'
import * as layer from '@/actions/layer'
import * as loading from '@/actions/loading'
import * as locale from '@/actions/locale'
import * as mediaQuery from '@/actions/mediaQuery'
import * as modal from '@/actions/modal'
import * as nav from '@/actions/nav'
import * as page from '@/actions/page'
import * as pointer from '@/actions/pointer'
import * as routing from '@/actions/routing'

export default {
  language,
  layer,
  loading,
  locale,
  mediaQuery,
  modal,
  nav,
  page,
  pointer,
  routing,
}
