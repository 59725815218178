import * as types from '../actions/types'

const initialState = {
  string: [],
  sites: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.STRING_GET_ALL:
      state = { ...state.data, ...action.data }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
