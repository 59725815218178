export default [
  '#D09882', // #0 Basic - Rose gold
  '#FFFFFF', // #1 Basic - White
  '#000000', // #2 Basic - Black
  '#f4f5f8', // #3
  '#1e1e1f', // #4 Grigio scuro
  '#F4F5F8', // #5 Basic - Ice
  '#A99E94', // #6 Basic - Brown
  '#E6E7E9', // #7 Basic - Grey
  '#979797', // #8 Footer text
  '#5c5c5c', // #9 Background grigio scuro => Template about .greyBackground
]

export const color = {
  primary: '#d09882',
  text: '#020202',
  text2: '#c1c1c1',
  background: '#f4f5f8',
  error: '#DB371F', // Error
  warning: '#ffcc00', // Yellow
  success: '#4caf50', // Green
}
