import { useState, useEffect, useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useFrame } from '@studio-freight/hamo'
import Lenis from '@studio-freight/lenis'
import useScrollStore from '@/store/scroll'
import { theme } from '@/styles/style'

gsap.registerPlugin(ScrollTrigger)

export default function useLenisScroll() {
  const [hash, setHash] = useState()
  const [lenis, setLenis] = useScrollStore((state) => [state.lenis, state.setLenis])

  useEffect(() => {
    window.scrollTo(0, 0)
    const lenisRef = new Lenis({
      duration: 1.2,
      easing: t => (t === 1 ? 1 : 1 - 2 ** (-10 * t)), // eslint-disable-line
      orientation: 'vertical',
      gestureOrientation: 'vertical',
      smoothWheel: true,
      smoothTouch: false,
      touchMultiplier: 2,
    })
    if (lenisRef) setLenis(lenisRef)

    return () => {
      lenisRef.destroy()
    }
  }, [])

  useLayoutEffect(() => {
    if (lenis && hash) {
      // scroll to on hash change
      const target = document.querySelector(hash)
      lenis.scrollTo(target, { offset: -1.1 - (theme.header.heightCompact + theme.header.heightSub) })
    }
  }, [lenis, hash])

  useLayoutEffect(() => {
    // catch anchor links clicks
    function onClick(e) {
      e.preventDefault()
      const node = e.currentTarget
      const hashRef = node.href.split('#').pop()
      setHash(`#${hashRef}`)
      setTimeout(() => {
        window.location.hash = hashRef
      }, 0)
    }

    const internalLinks = [...document.querySelectorAll('[href]')].filter(
      (node) => node.href.includes('#'),
    )

    internalLinks.forEach((node) => {
      node.addEventListener('click', onClick, false)
    })

    return () => {
      internalLinks.forEach((node) => {
        node.removeEventListener('click', onClick, false)
      })
    }
  }, [])

  useFrame((time) => {
    if (lenis) lenis.raf(time)
  }, [lenis])

  useEffect(() => {
    if (lenis) lenis.on('scroll', ScrollTrigger.update)
  }, [lenis])

  return null
}
