const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      zIndex: theme.zindex.loader,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100%',
      color: theme.colors[2],
      backgroundColor: '#fff',
      transformOrigin: 'bottom center',
      willChange: 'transform',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    logo: {
      position: 'relative',
      zIndex: 1,
      top: 0,
      left: 0,
      '& span': {
        position: 'absolute',
        display: 'block',
        zIndex: 10,
        top: '-10%',
        height: '110%',
        backgroundColor: theme.colors[1],
        '&:nth-of-type(1)': {
          left: 0,
          width: '21px',
        },
        '&:nth-of-type(2)': {
          left: '21px',
          width: '10px',
        },
        '&:nth-of-type(3)': {
          left: '31px',
          width: '25px',
        },
        '&:nth-of-type(4)': {
          left: '56px',
          width: '35px',
        },
      },
    },
  }
  return output
}

export default style
