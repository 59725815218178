export default (theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: 'calc(100 * var(--vh))',
    zIndex: theme.zindex.nav,
    overflow: 'hidden',
  },
  compact: {
    '& $langNavDesktop': {
      top: 21,
    },
  },
  nav: {
    display: 'flex',
    background: theme.colors[2],
    fontFamily: theme.fonts[0],
    fontWeight: 300,
    padding: theme.header.spacing,
    paddingTop: 160,
    position: 'relative',
    zIndex: 1,
    [theme.mediaInverse.sm]: {
      padding: theme.header.spacingMobile,
      paddingTop: 95,
      display: 'block',
      overflowY: 'auto',
      height: 'calc(100 * var(--vh))',
    },
  },
  navCol: {
    flex: '0 0 20%',
    paddingRight: 20,
  },
  navCol4: {
    flex: '1 0 auto',
  },
  /*------------------------------
  Last Col
  ------------------------------*/
  navCol5: {
    justifySelf: 'right',
    paddingRight: 30,
    flex: '0 1 auto',
    paddingBottom: 30,
    [theme.mediaInverse.md]: {
      paddingRight: 0,
    },
    '& $navItem': {
      margin: '0 0 10px 0',
    },
    '& $itemBig': {
      fontSize: 16,
      color: theme.colors[6],
      textTransform: 'none',
      [theme.mediaInverse.md]: {
        fontSize: 14,
      },
      [theme.mediaInverse.sm]: {
        fontSize: 16,
      },
    },
  },
  /*------------------------------
  Social Nav
  ------------------------------*/
  navCol6: {
    position: 'absolute',
    zIndex: 1,
    bottom: 50,
    right: theme.header.spacing[1],
    display: 'flex',
    color: theme.colors[6],
    fontSize: 13,
    fontWeight: 400,
    '& $navItem': {
      margin: '0 0 20px 0',
      display: 'inline',
      '&:not(:last-child)': {
        '&:after': {
          content: '"/"',
          margin: '0 8px',
        },
      },
    },
    '& $itemBig': {
      fontSize: 13,
      letterSpacing: 1.3,
      color: theme.colors[6],
      [theme.mediaInverse.md]: {
        fontSize: 11,
      },
      [theme.mediaInverse.sm]: {
        fontSize: 13,
      },
    },
    [theme.mediaInverse.md]: {
      right: 30,
      fontSize: 11,
    },
    [theme.mediaInverse.sm]: {
      fontSize: 13,
      position: 'static',
    },
  },
  navItem: {
    margin: '0 0 20px 0',
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    [theme.mediaInverse.sm]: {
      margin: '10px 0 25px',
    },
  },
  itemBig: {
    width: '100%',
    fontSize: 24,
    padding: 0,
    lineHeight: 1,
    textDecoration: 'none',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: theme.colors[0],
    fontFamily: theme.fonts[0],
    fontWeight: 300,
    '&:is(a)': {
      transition: 'opacity .3s ease-in-out, color .3s ease-in-out',
      cursor: 'pointer',
      '&:hover, &.current_page_item': {
        opacity: '1!important',
        color: `${theme.colors[1]}!important`,
      },
    },
    '& + ul': {
      marginTop: 10,
      [theme.mq.sm]: {
        marginTop: 0,
      },
    },
    [theme.mediaInverse.md]: {
      fontSize: 18,
    },
    [theme.mediaInverse.sm]: {
      fontSize: 21,
    },
  },
  itemSmall: {
    fontSize: 16,
    textTransform: 'none',
    lineHeight: 1.2,
    fontFamily: theme.fonts[1],
    '& a': {
      display: 'inline-block',
      padding: '10px 20px 10px 0',
      color: theme.colors[6],
      transition: 'color .3s ease-out',
      textDecoration: 'none',
      [theme.mq.sm]: {
        padding: 0,
      },
      '@media (hover: hover)': {
        '&:hover': {
          color: theme.colors[1],
        },
      },
    },
    [theme.mediaInverse.md]: {
      fontSize: 14,
    },
    [theme.mediaInverse.sm]: {
      fontSize: 16,
      borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
      margin: 0,
      padding: '15px 0',
    },
  },
  /*------------------------------
  Lang Nav Desktop
  ------------------------------*/
  langNavDesktop: {
    position: 'absolute',
    zIndex: 11,
    top: 46,
    right: 125,
    transition: 'top .2s ease-out',
    [theme.mediaInverse.sm]: {
      display: 'none',
    },
  },
  /*------------------------------
  Lang Nav Mobile
  ------------------------------*/
  langNavMobile: {
    display: 'none',
    [theme.mediaInverse.sm]: {
      display: 'block',
      margin: '30px 0 35px',
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'inline-block',
        marginRight: 10,
      },
      '& a': {
        display: 'block',
        width: theme.header.burger.height,
        height: theme.header.burger.height,
        lineHeight: `calc(${theme.header.burger.height} + 2px)`,
        borderRadius: '100%',
        textAlign: 'center',
        fontFamily: theme.fonts[0],
        fontSize: 10,
        margin: 0,
        padding: 0,
        textDecoration: 'none',
        textTransform: 'uppercase',
        background: theme.colors[1],
        color: theme.colors[2],
        transition: 'all .3s ease-in-out',
        cursor: 'pointer',
      },
    },
  },
  overlay: {
    width: '100vw',
    height: 'calc(var(--vh) * 100)',
    position: 'fixed',
    left: 0,
    top: 0,
    background: theme.getRgba(theme.color.text, 0.45),
    zIndex: 1,
  },
})
