import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { CONFIG } from '@/constants'
import { cleanOrigin, isInternal } from '@/utils/path'
import { history } from '@/base/store'
import { setReady, setRealLink } from '@/actions/loading'

class DelayLink extends Component {
  constructor(props) {
    super(props)
    this.timeout = null
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  /*------------------------------
  Component Will Unmount
  ------------------------------*/
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  /**
   * Called when the link is clicked
   *
   * @param {Event} e
   */
  /*------------------------------
  Handle Click
  ------------------------------*/
  handleClick = (e) => {
    if (e.defaultPrevented) return
    e.preventDefault()
    const { replace, to, delay, onDelayStart, onDelayEnd, doAnimation } = this.props
    const link = cleanOrigin(to)

    if (history.location.pathname !== link) {
      this.props.setLoading(false)
      onDelayStart(e, link)

      if (doAnimation !== undefined) {
        doAnimation()
      }

      this.timeout = setTimeout(() => {
        this.props.setRealLink(link)
        if (replace) {
          history.replace(link)
        } else {
          history.push(link)
        }
        onDelayEnd(e, link)
      }, delay)
    }
  }

  /*------------------------------
  Render
  ------------------------------*/
  render() {
    const props = { ...this.props }
    delete props.delay
    delete props.onDelayStart
    delete props.onDelayEnd
    delete props.setLoading
    delete props.setRealLink
    delete props.hover
    delete props.load
    delete props.loading
    delete props.doAnimation
    delete props.onRef
    delete props.inview
    delete props.back
    delete props.title // rimosso per non vedere tooltip browser, ma servirebbe per la SEO
    const url = cleanOrigin(props.to)?.split('.')
    if (!isInternal(props.to) || (url.length > 1)) {
      return (
        <a href={props.to} target="_blank" rel="noopener" title={props.title} className={props.className}>
          {props.children}
        </a>
      )
    }
    props.to = cleanOrigin(props.to)
    return (
      <Link
        onClick={this.handleClick}
        onMouseUp={this.props.onMouseUp}
        {...props}
      />
    )
  }
}

DelayLink.defaultProps = {
  delay: CONFIG.LINK_DELAY,
  to: '#',
  onDelayStart: () => {},
  onDelayEnd: () => {},
  onRef: () => {},
}
const mapStateToProps = (state) => ({
  loading: state.loading,
})
const mapDispatchToProps = (dispatch) => ({
  setLoading: (state) => dispatch(setReady(state)),
  setRealLink: (state) => dispatch(setRealLink(state)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DelayLink)
