export default {
  hasTouchWin: navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1,
  hasPointer: !!window.navigator.msPointerEnabled,
  hasTouch: 'ontouchstart' in document,
  isIphone: !!navigator.platform && /iPhone/.test(navigator.platform),
  isSafari: navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1,
  isIE11: !!window.MSInputMethodContext && !!document.documentMode,
  isEdge: window.navigator.userAgent.indexOf('Edge') > -1,
  isNativeScroll: ('ontouchstart' in document && window.innerWidth <= 1280) || (!!window.MSInputMethodContext && !!document.documentMode) || window.navigator.userAgent.indexOf('Edge') > -1, // (hasTouch && winW <= 1280) || isIE11 || isEdge
  passive: () => {
    let passiveIfSupported = false
    try {
      window.addEventListener('test', null, Object.defineProperty({}, 'passive', { get: () => passiveIfSupported = { passive: true } }))
    } catch (err) {
      window.console.log('error:: passive event')
    }
    return passiveIfSupported
  },
}
