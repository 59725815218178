const style = (theme) => {
  const output = {
    root: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'block',
      zIndex: 101,
    },
    container: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    text: {
      textAlign: 'center',
      position: 'absolute',
      left: 0,
      bottom: 30,
      padding: [0, 40],
      width: '100%',
      '& h2': {
        color: theme.colors[1],
        fontFamily: theme.fonts[0],
        fontSize: 25,
        lineHeight: 1.2,
        marginBottom: 5,
        fontWeight: 300,
        transformOrigin: 'bottom center',
      },
      '& p': {
        color: theme.colors[1],
        fontFamily: theme.fonts[0],
        fontSize: 14,
        lineHeight: 1.2,
        textTransform: 'uppercase',
        margin: 0,
        transformOrigin: 'bottom center',
        opacity: 0.7,
      },
      '& a': {
        color: theme.colors[1],
        fontFamily: theme.fonts[0],
        fontSize: 14,
        lineHeight: 1.2,
        textTransform: 'none',
        textDecoration: 'none',
        '&.download': {
          display: 'inline-block',
          position: 'relative',
          zIndex: 1,
          paddingLeft: 31,
          marginLeft: 4,
          '&:before': {
            content: '"|"',
            position: 'absolute',
            top: 0,
            left: 0,
          },
          '& .ico': {
            position: 'absolute',
            top: -2,
            left: 10,
            width: 16,
            height: 16,
            background: theme.colors[1],
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              width: 8,
              height: 8,
              fill: theme.colors[2],
            },
          },
        },
      },
    },
    image: {
      zIndex: 1,
      order: 2,
      position: 'relative',
      overflow: 'hidden',
      margin: 'auto',
      padding: 50,
      height: 'calc(100vh - 100px)',
      width: 'calc(100vw - 100px)',
      '& img': {
        position: 'absolute',
        zIndex: 1,
        top: 50,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    background: {
      backgroundColor: theme.getRgba(theme.colors[2], 0.96),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    btnClose: {
      position: 'fixed',
      zIndex: 10,
      top: 45,
      right: 45,
      '& button': {
        cursor: 'pointer',
        background: theme.colors[1],
        borderRadius: '50%',
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& svg': {
        width: 30,
        height: 30,
        fill: theme.colors[2],
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    image: {
      padding: 30,
      width: 'calc(100vw - 40px)',
      height: 'calc(100vh - 80px)',
      '& img': {
        top: 20,
        left: 0,
      },
    },
    text: {
      padding: [0, 20],
      '& h2': {
        fontSize: 20,
      },
      '& p': {
        fontSize: 12,
      },
    },
    btnClose: {
      top: 20,
      right: 20,
      '& button': {
        width: 35,
        height: 35,
      },
      '& svg': {
        width: 20,
        height: 20,
        fill: theme.colors[2],
      },
    },
  }

  return output
}

export default style
