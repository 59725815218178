import { mediaInverse } from '@/styles/mq'

export default {
  absolute: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  spacerXL: {
    default: 200,
    sm: 100,
  },
  spacerLG: {
    default: 150,
    sm: 75,
  },
  spacerMD: {
    default: 100,
    sm: 50,
  },
  spacerSM: {
    default: 50,
    sm: 25,
  },
  spacerXS: {
    default: 24,
    sm: 12,
  },
  spacerXXS: {
    default: 24,
    sm: 12,
  },
  container: {
    '--gutter': '60px',
    marginLeft: 'var(--gutter)',
    marginRight: 'var(--gutter)',
    [mediaInverse.sm]: {
      '--gutter': '20px',
    },
  },
  grid: {
    '--cols': '8',
    gap: 'var(--gutter)',
    width: 'calc(100% + var(--gutter))',
    marginRight: 'calc(-1 * var(--gutter))',
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    '--size': '12',
    '--offset': '0',
    marginBottom: 'calc(-1 * var(--gutter))',

    flexBasis: 'calc((var(--size) / var(--cols) * 100%) - var(--gutter))',
    marginLeft: 'calc((var(--offset) / var(--cols) * 100%))',
  },
  getCol: (size, offset = 0) => {
    return {
      '--size': size,
      '--offset': offset,
      flexBasis: 'calc((var(--size) / var(--cols) * 100%) - var(--gutter))',
      marginLeft: 'calc((var(--offset) / var(--cols) * 100%))',
    }
  },
  sectionVerticalMargin: {
    marginTop: 240,
    marginBottom: 240,
    [mediaInverse.md]: {
      marginTop: 180,
      marginBottom: 180,
    },
    [mediaInverse.sm]: {
      marginTop: 120,
      marginBottom: 120,
    },
  },
}
