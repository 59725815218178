import * as types from '../actions/types'

const initialState = {
  open: false,
  image: false,
  text: false,
  node: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MODAL_SET_OPEN:
      state = {
        ...state,
        open: action.setOpen,
      }
      break
    case types.MODAL_SET_IMAGE:
      state = {
        ...state,
        image: action.setImage,
      }
      break
    case types.MODAL_SET_TEXT:
      state = {
        ...state,
        text: action.setText,
      }
      break
    case types.MODAL_SET_NODE:
      state = {
        ...state,
        node: action.setNode,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
