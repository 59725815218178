import colors from '@/styles/colors'
import mq from '@/styles/mq'

export const fonts = [
  '"DIN Next LT Pro", serif',
  'Helvetica Neue', 'Helvetica, Arial, sans-serif',
]

export const typography = {
  general: {
    fontFamily: fonts[3],
    fontSize: 16,
    lineHeight: 1.5,
    color: colors[2],
  },
  headings: {
    color: colors[4],
    fontFamily: fonts[0],
    lineHeight: 0.95,
    '& strong, & b': {
      color: colors[0],
    },
    '& p': {
      margin: 0,
    },
  },
  h1: {
    color: colors[0],
    fontSize: 64,
    textIndent: '-0.1em',
  },
  h2: {
    fontSize: 54,
  },
  h3: {
    fontSize: 40,
  },
  h4: {
    fontSize: 26,
  },
  pretit: {
    color: colors[4],
    fontSize: 9,
    marginBottom: 30,
    textTransform: 'uppercase',
    letterSpacing: '0.34em',
  },
  button: {
    color: 'rgba(0, 0, 0, 0)',
    borderRadius: 33,
    padding: '14px 33px 11px',
    textDecoration: 'none',
    verticalAlign: 'middle',
    fontFamily: fonts[0],
    display: 'inline-block',
    cursor: 'pointer',
    transition: 'color .12s ease-in-out',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    backgroundColor: colors[4],
    whiteSpace: 'nowrap',
    '&:before, &:after': {
      content: 'attr(data-text)',
      position: 'absolute',
      zIndex: 1,
      top: 14,
      left: 0,
      color: colors[1],
      padding: '0 33px',
      transition: 'transform .28s ease-in-out, opacity .28s ease-in-out',
    },
    '&:after': {
      opacity: 0,
      transform: 'translateY(100%) skewX(5deg)',
    },
    '&:hover': {
      '&:before': {
        opacity: 0,
        transform: 'translateY(-100%) skewX(5deg)',
      },
      '&:after': {
        opacity: 1,
        transform: 'translateY(0%)',
      },
    },
  },
  buttonMobile: {
    padding: '10px 25px 7px',
    '&:before, &:after': {
      top: 10,
      padding: '0 25px',
    },
  },
  text: {
    maxWidth: 700,
    margin: '0 auto',
    padding: '110px 0 60px',
    color: colors[8],
    fontSize: 22,
    lineHeight: 1.45,
    '& figure': {
      margin: [80, -40],
      [mq.md]: {
        margin: [40, 0],
      },
    },
    '& figcaption': {
      fontSize: 13,
      marginTop: 10,
    },
    '& a': {
      color: colors[0],
    },
    '& h2, & h3, & h4': {
      fontSize: 50,
      color: colors[8],
      textAlign: 'center',
      margin: [100, -40],
      lineHeight: 1.1,
      [mq.md]: {
        textAlign: 'left',
        fontSize: 28,
        margin: [40, 0],
      },
    },
    '& .wp-block-gallery': {
      margin: [80, 0],
      padding: 0,
      listStyle: 'none',
      [mq.md]: {
        margin: [40, 0],
      },
      '& li': {
        textAlign: 'center',
        margin: 0,
        padding: 0,
      },
    },
    '& .wp-embed-aspect-16-9': {
      position: 'relative',
      zIndex: 1,
      height: 0,
      paddingBottom: '56.25%',
      '& > div, & iframe': {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
      },
      '& .wp-embed-aspect-4-3': {
        position: 'relative',
        zIndex: 1,
        height: 0,
        paddingBottom: '75%',
        '& > div, & iframe': {
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        },
      },
    },
  },
}
