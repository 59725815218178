import { useEffect, useCallback, createContext } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import usePrevious from '@/hooks/usePrevious'
import actions from '@/actions'

export const LoaderEngineContext = createContext()

function LoaderEngine({
  children,
}) {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, firstLoad, ready } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    firstLoad: state.loading.firstLoad,
    ready: state.loading.ready,
  }), shallowEqual)

  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setPageShow = useCallback((value) => dispatch(actions.page.setShow(value)), [dispatch])
  const setPageReady = useCallback((value) => dispatch(actions.loading.setReady(value)), [dispatch])
  const setLoadingFirst = useCallback((value) => dispatch(actions.loading.setFirstLoad(value)), [dispatch])

  /*------------------------------
  useEffect --> pathname
  ------------------------------*/
  useEffect(() => {
    if (prevPathname !== undefined && prevPathname !== pathname) {
      setPageReady(false)
      setLoadingFirst(false)
      setPageShow(false)
    }
  }, [pathname])

  return (
    <LoaderEngineContext.Provider
      value={{ // eslint-disable-line
        ready,
        firstLoad,
      }}
    >
      {children}
    </LoaderEngineContext.Provider>
  )
}

export default LoaderEngine
