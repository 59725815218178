import { createRoot } from 'react-dom/client'
import { JssProvider } from 'react-jss'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { store, history } from '@/base/store'
import '@/detects/modernizr'
import AppComponent from '@/components/App'
import '@/base/gsap'

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
          <AppComponent />
        </JssProvider>
      </ConnectedRouter>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
