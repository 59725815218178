import * as types from '../actions/types'

const initialState = {
  mouseprogresstime: 0,
  hover: 0,
  icon: false,
  label: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.POINTER_PROGRESS_TIME:
      state = {
        ...state,
        mouseprogresstime: action.setPointerProgressTime,
      }
      break
    case types.POINTER_HOVER:
      state = {
        ...state,
        hover: action.setHover,
      }
      break
    case types.POINTER_ICON:
      state = {
        ...state,
        icon: action.setIcon,
      }
      break
    case types.POINTER_LABEL:
      state = {
        ...state,
        label: action.setLabel,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
