import { mainCacheAxios as axios, mainAxios } from '@/base/axios'
import { API, CONFIG, WP_APP_PASSWORD } from '@/constants'
import { convertJsontoFormData } from '@/utils/form'
import * as types from './types'
import * as loader from './loading'

export function getDataPage(slugPage) {
  const content = []
  return (dispatch, getState) => {
    const params = {
      params: {
        slug: slugPage,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(API.PAGE, params)
        .then((response) => {
          const data = response.data[0]
          content[slugPage] = data
          dispatch({
            type: types.PAGE_SET_CURRENT,
            setShow: slugPage,
            setType: 'pages',
          })
          dispatch({
            type: types.PAGE_ADD_CONTENT,
            content,
          })
          loader.setReady(true)
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function getDataPageNoCache(slugPage) {
  const content = []
  return (dispatch, getState) => {
    const params = {
      params: {
        slug: slugPage,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(API.PAGE, params)
        .then((response) => {
          const data = response.data[0]
          content[slugPage] = data
          dispatch({
            type: types.PAGE_SET_CURRENT,
            setShow: slugPage,
            setType: 'pages',
          })
          dispatch({
            type: types.PAGE_ADD_CONTENT,
            content,
          })
          loader.setReady(true)
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function getDataPost(slugPage) {
  const content = []
  return (dispatch, getState) => {
    const params = {
      params: {
        slug: slugPage,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(API.POST, params)
        .then((response) => {
          const data = response.data[0]
          content[slugPage] = data
          dispatch({
            type: types.PAGE_SET_CURRENT,
            setShow: slugPage,
            setType: 'posts',
          })
          dispatch({
            type: types.PAGE_ADD_POST,
            content,
          })
          loader.setReady(true)
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
export function getListPost(numItem = 100, paged = 1) {
  return (dispatch, getState) => {
    const params = {
      params: {
        order: 'desc',
        orderby: 'date',
        per_page: numItem * paged,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(API.POST, params)
        .then((response) => {
          const listPost = []
          listPost.post = response.data.map((value) => {
            const content = []
            content[value.slug] = value
            dispatch({
              type: types.PAGE_ADD_POST,
              content,
            })
            return value.slug
          })
          listPost.postTotal = response.headers['x-wp-total']
          listPost.postPage = Math.ceil(response.headers['x-wp-total'] / numItem)
          dispatch({
            type: types.PAGE_LIST_CONTENT,
            list: listPost,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
export function getDataCPT(cpt, slugPage) {
  const content = []
  return (dispatch, getState) => {
    const params = {
      params: {
        slug: slugPage,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.BASE_URL}/${cpt}`, params)
        .then((response) => {
          const data = response.data[0]
          content[slugPage] = data
          loader.setReady(true)
          dispatch({
            type: types.PAGE_SET_CURRENT,
            setShow: slugPage,
            setType: 'pages',
          })
          dispatch({
            type: types.PAGE_ADD_CONTENT,
            content,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
export function getListCPT(cpt, num_item = 1000) {
  return (dispatch, getState) => {
    const params = {
      params: {
        per_page: num_item,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.BASE_URL}/${cpt}`, params)
        .then((response) => {
          const listPost = []
          listPost[`${cpt}`] = response.data.map((value) => {
            const content = []
            content[value.slug] = value
            dispatch({
              type: types.PAGE_ADD_CONTENT,
              content,
            })
            return value.slug
          })
          dispatch({
            type: types.PAGE_LIST_CONTENT,
            list: listPost,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
export function getThisCPT(cpt, list = 0, num_item = 100) {
  return (dispatch, getState) => {
    const params = {
      params: {
        order: 'asc',
        orderby: 'menu_order',
        per_page: num_item,
        include: list,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.BASE_URL}/${cpt}`, params)
        .then((response) => {
          const listPost = []
          listPost[`${cpt}`] = response.data.map((value) => {
            const content = []
            content[value.slug] = value
            dispatch({
              type: types.PAGE_ADD_CONTENT,
              content,
            })
            return value.slug
          })
          dispatch({
            type: types.PAGE_LIST_CONTENT,
            list: listPost,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function getListTaxonomy(tax, cpt, list) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const params = {
        params: {
          [`${CONFIG.CUSTOM_TAXONOMY_PREFIX}${tax}`]: list,
          lang: getState().locale.currentLanguage,
        },
      }
      axios
        .get(`${API.BASE_URL}/${cpt}`, params)
        .then((response) => {
          const listPost = []
          listPost[`${CONFIG.CUSTOM_TAXONOMY_PREFIX}${tax}`] = response.data.map((value) => {
            const content = []
            content[value.slug] = value
            dispatch({
              type: types.PAGE_ADD_CONTENT,
              content,
            })
            return value.slug
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
export function getListTerms(tax, cpt, term) {
  return (dispatch, getState) => {
    const params = {
      params: {
        [`filter[${tax}]`]: term,
        per_page: 10000,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.BASE_URL}/${cpt}`, params)
        .then((response) => {
          const listPost = []
          listPost[`${term}`] = response.data.map((value) => {
            const content = []
            content[value.slug] = value
            dispatch({
              type: types.PAGE_ADD_CONTENT,
              content,
            })
            return value.slug
          })
          dispatch({
            type: types.PAGE_LIST_CONTENT,
            list: listPost,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}
export function getAllTerms(tax) {
  return (dispatch, getState) => {
    const params = {
      params: {
        per_page: 10000,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.BASE_URL}/${tax}`, params)
        .then((response) => {
          const listPost = {}
          listPost[tax] = {}
          for (let i = 0; i < response.data.length; i += 1) {
            const item = response.data[i]
            const { name, slug, link, id, count, cover, parent, description, yoast_head, family_detail_count, is_configurable } = item
            listPost[tax][item.slug] = { name, slug, link, id, count, cover, parent, description, yoast_head, family_detail_count, is_configurable }
          }
          dispatch({
            type: types.PAGE_LIST_TAXONOMIES,
            list: listPost,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function getMedia(numItem = 500, paged = 1) {
  return (dispatch, getState) => {
    const params = {
      params: {
        per_page: numItem,
        page: paged,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(API.MEDIA, params)
        .then((response) => {
          const media = []
          response.data.forEach((value) => {
            media[value.id] = value
          })
          dispatch({
            type: types.PAGE_ADD_MEDIA,
            data: media,
          })
          dispatch({
            type: types.LOADING_SET_INC_STATUS,
            setIncrementalStatus: getState().loading.incremental + 1,
          })
          if (response.headers['x-wp-total'] > numItem * paged) {
            dispatch(getMedia(numItem, paged + 1))
          }
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function getDataForm(idForm) {
  const content = []
  return (dispatch, getState) => {
    const params = {
      params: {
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`${API.FORM}/${idForm}`, params)
        .then((response) => {
          const { data } = response
          content[idForm] = data
          dispatch({
            type: types.PAGE_ADD_FORM,
            content,
          })
          const result = {
            status: '',
            message: '',
          }
          dispatch({
            type: types.PAGE_STATUS_FORM,
            result,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function sendForm(idForm, params) {
  const paramSafe = Object.keys(params).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&')
  const headers = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      mainAxios
        .post(`${API.BASE_URL.CF7_FEEDBACK}/${idForm}/feedback`, paramSafe, headers)
        .then((response) => {
          const { data } = response
          const result = {
            status: 'error',
            message: data.message,
          }
          if (data.status === 'mail_sent') {
            result.status = 'success'
          }
          dispatch({
            type: types.PAGE_STATUS_FORM,
            result,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export function resetForm() {
  return (dispatch) => {
    const result = {
      status: '',
      message: '',
    }
    dispatch({
      type: types.PAGE_STATUS_FORM,
      result,
    })
  }
}

export function setTransition(style) {
  return {
    type: types.PAGE_SET_TRANSITION,
    setTransition: style,
  }
}

export function setLayout(style) {
  return {
    type: types.PAGE_SET_LAYOUT,
    setLayout: style,
  }
}

export function setShow(bool) {
  return {
    type: types.PAGE_SET_SHOW,
    setShow: bool,
  }
}
export function setCurrent(slug, type) {
  return {
    type: types.PAGE_SET_CURRENT,
    setShow: slug,
    setType: type,
  }
}
export function setNegative(bool) {
  return {
    type: types.SET_NEGATIVE,
    payload: bool,
  }
}

export function getSearchFrom(cpt, value) {
  const content = []
  return (dispatch, getState) => {
    const params = {
      params: {
        search: value,
        type: cpt,
        lang: getState().locale.currentLanguage,
      },
    }
    return new Promise((resolve, reject) => {
      mainAxios
        .get(`${API.BASE_URL}/searching`, params)
        .then((response) => {
          const { data } = response
          const results = data.map((i) => {
            const pages = []
            pages[i.slug] = i

            const { slug, featured_media, featured_media_url, id, link, title, youtubeID } = i

            return {
              slug,
              featured_media,
              featured_media_url,
              id,
              link,
              title,
              youtubeID,
            }
          })

          // Dispatch sul search
          content[cpt] = results
          dispatch({
            type: types.GET_SEARCH,
            content,
          })
          resolve(response)
        })
        .catch((response) => {
          reject(response)
        })
    })
  }
}

export const fetchForm = (id) => async () => {
  const response = await mainAxios.get(`${API.CF7_GET}/${id}?lang=en`)
  return response.data
}

export const sendContactForm = (id, data, isMultipart) => async () => {
  const params = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } }
  if (isMultipart) params.headers = { 'Content-Type': 'multipart/form-data' }
  const response = await mainAxios.post(
    `${API.CF7_FEEDBACK}/${id}/feedback?lang=en`,
    convertJsontoFormData(data),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  )
  return response
}

export function saveConfiguration(title) {
  return async (dispatch, getState) => {
    const response = await mainAxios.post(
      `${API.CONFIGURATION}?lang=${getState().locale.currentLanguage}`,
      JSON.stringify({
        title,
        status: 'publish',
        content: getState().layer.currentProject,
      }),
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${WP_APP_PASSWORD}`,
      } },
    )
    return response
  }
}
