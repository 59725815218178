import { string, number, boolean, mixed, object, ref } from 'yup'
import { mainAxios as axios } from '@/base/axios'

export const createYupSchema = (config, lang = 'en') => {
  const errors = require(`@/utils/form/errors/${lang}.json`) // eslint-disable-line
  const schema = config.reduce((obj, item) => {
    let value = {}
    if (item.type === 'text' || item.type === 'textarea' || item.type === 'select' || item.type === 'hidden') {
      value = string()
      if (item.required) value = value.required(errors['required'])
    }
    if (item.type === 'email') {
      value = string().email(errors['email'])
      if (item.required) value = value.required(errors['required'])
    }
    if (item.type === 'password') {
      value = string()
      if (item.required) value = value.min(8, errors['password']).required(errors['required'])
    }
    if (item.type === 'confirm_password') {
      value = string()
      if (item.required) value = value.required(errors['required']).oneOf([ref('user_pass'), null], errors['confirm_password'])
    }
    if (item.type === 'tel') {
      value = number('').typeError(errors['tel'])
      if (item.required) value = value.required(errors['required'])
    }
    if (item.type === 'checkbox') {
      value = boolean()
      if (item.required) value = value.oneOf([true], errors['checkbox']).required(errors['required'])
    }
    if (item.type === 'file') {
      value = mixed()
      if (item.required) value = value.required(errors['required'])
    }
    return {
      ...obj,
      [item.name]: value,
    }
  }, {})

  return object().shape(schema)
}

export const createInitialValues = (fields, initialValues = []) => {
  const values = fields.reduce((obj, item) => {
    return {
      ...obj,
      [item.name]: item.type === 'checkbox' ? false : '',
    }
  }, {})
  return Object.assign(values, ...initialValues, { _wpcf7_recaptcha_response: '' })
}

export const convertJsontoUrlencoded = (obj) => {
  const str = []
  for (const key in obj) { // eslint-disable-line no-restricted-syntax
    if (obj.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    }
  }
  return str.join('&')
}

export const convertJsontoFormData = (obj) => {
  const formData = new FormData()

  for (const key in obj) { // eslint-disable-line no-restricted-syntax
    if (obj.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      formData.append(key, obj[key])
    }
  }
  return formData
}

export const getPromiseFromFetch = ({
  params = {},
  config = {},
  method = 'get',
  url,
}) => new Promise((resolve) => axios[method]( // eslint-disable-line
  url,
  method === 'get' ? config : params,
  config,
)
  .then(resolve)
  .catch((error) => resolve(error.response)))
