import {
  OPEN_LAYER,
  CLOSE_LAYER,
  CLOSE_ALL_LAYER,
  CREATE_LAYER_MODEL,
  PUSH_PROJECT,
  RESET_PROJECT,
} from '@/actions/types'

const createLayerModel = ({ id }) => (dispatch) => {
  dispatch({
    type: CREATE_LAYER_MODEL,
    payload: id,
  })
}

const openLayer = (data) => (dispatch) => {
  dispatch({
    type: OPEN_LAYER,
    payload: data,
  })
}

const pushProject = (data) => (dispatch) => {
  dispatch({
    type: PUSH_PROJECT,
    payload: data,
  })
}

const resetProject = () => (dispatch) => dispatch({ type: RESET_PROJECT })

const closeLayer = (data) => (dispatch) => {
  dispatch({
    type: CLOSE_LAYER,
    payload: data,
  })
}

const closeAllLayer = () => (dispatch) => dispatch({ type: CLOSE_ALL_LAYER })

const openMenu = (data) => (dispatch) => {
  dispatch(openLayer({ ...data, id: 'menu' }))
}

const closeMenu = (data) => (dispatch) => {
  dispatch(closeLayer({ ...data, id: 'menu' }))
}

export {
  createLayerModel,
  openLayer,
  closeLayer,
  closeAllLayer,
  openMenu,
  closeMenu,
  pushProject,
  resetProject,
}
