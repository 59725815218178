const style = (theme) => {
  const output = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -10,
      marginRight: -10,
    },
    formDisabled: {
      '& *': {
        pointerEvents: 'none !important',
      },
      pointerEvents: 'none',
      opacity: 0.5,
    },
    formControl: {
      width: '100%',
      padding: '0 10px',
      marginBottom: 30,
      position: 'relative',
      zIndex: 1,
      '&.half': {
        width: '50%',
      },
    },
    formControlTextarea: {
      width: '100%',
      marginBottom: 30,
    },
    formControlCheckbox: {
      width: '100%',
      marginBottom: 30,
      '& $error': {
        left: 50,
      },
    },
    formControlSelect: {
      width: '100%',
      marginBottom: 30,
      zIndex: 2,
    },
    formControlOptional: {
      height: 0,
      marginBottom: 0,
      overflow: 'hidden',
      transition: 'all 0.6s ease-out',
    },
    formControlOptionalVisible: {
      height: 57,
      marginBottom: 30,
    },
    submit: {
      borderRadius: 20,
      background: theme.colors[2],
      color: theme.colors[1],
      padding: '8px 20px',
      margin: '0 auto',
      pointerEvents: 'all',
      fontFamily: theme.fonts[1],
      fontSize: 18,
      cursor: 'pointer',
    },

    // Generic Input
    input: {
      width: '100%',
      color: theme.colors[2],
      fontFamily: theme.fonts[1],
      appearance: 'none',
      padding: '13px 28px',
      fontSize: 14,
      borderRadius: 50,
      background: 'transparent',
      border: `1px solid ${theme.colors[2]}`,
      transition: 'border 0.2s ease-in',
      '&::placeholder': {
        fontFamily: theme.fonts[0],
        color: theme.getRgba(theme.colors[2], 0.5),
      },
    },
    formControlDisabled: {
      '& $input': {
        color: theme.getRgba(theme.colors[2], 0.5),
      },
    },

    // Textarea
    textarea: {
      width: '100%',
      color: theme.colors[2],
      fontFamily: theme.fonts[1],
      background: theme.getRgba(theme.colors[2], 0.05),
      appearance: 'none',
      padding: '16px 28px',
      fontSize: 14,
      fontWeight: 300,
      borderRadius: 10,
      resize: 'none',
      border: '2px solid transparent',
      transition: 'border 0.3s ease-in',
      '&::placeholder': {
        fontFamily: theme.fonts[0],
        color: theme.getRgba(theme.colors[2], 0.3),
      },
    },

    // Select
    select: {
      '& .customSelect__control': {
        color: theme.colors[2],
        background: 'transparent',
        fontSize: 14,
        fontFamily: theme.fonts[0],
        borderRadius: 50,
        padding: '5px 28px',
        border: `1px solid ${theme.colors[2]}`,
        '&:hover': {
          borderColor: theme.colors[2],
        },
      },
      '& .customSelect__control--is-focused': {
        border: `1px solid ${theme.colors[2]}`,
      },
      '& .customSelect__placeholder': {
        color: theme.getRgba(theme.colors[2], 0.5),
      },
      '& .customSelect__indicator': {
        color: theme.colors[2],
      },
      '& .customSelect__menu': {
        color: theme.colors[2],
        background: theme.colors[1],
        fontSize: 14,
        fontFamily: theme.fonts[0],
        borderRadius: 25,
        overflow: 'hidden',
        border: `1px solid ${theme.colors[2]}`,
      },
      '& .customSelect__single-value': {
        color: theme.colors[2],
        fontFamily: theme.fonts[1],
      },
      '& .customSelect__menu-list': {
        paddingTop: 10,
        paddingBottom: 10,
      },
      '& .customSelect__value-container': {
        padding: 0,
      },
      '& .customSelect__indicator-separator': {
        display: 'none',
      },
      '& .customSelect__option--is-focused': {
        background: '#fae189',
      },
      '& .customSelect__option--is-selected': {
        background: theme.colors[2],
      },
    },

    // Checkbox
    checkbox: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      fontSize: 14,
      '& input[type="checkbox"]': {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
      },
      '& input[type="checkbox"] + span': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        paddingLeft: 30,
        '& a': {
          color: 'currentColor',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          cursor: 'pointer',
          zIndex: 1,
          width: 20,
          height: 20,
          marginRight: 10,
          borderRadius: '50%',
          border: `1px solid ${theme.colors[2]}`,
          background: theme.colors[1],
          transition: 'all .2s ease-in-out',
        },
      },
      '& input[type="checkbox"]:checked + span': {
        '&:before': {
          background: theme.colors[2],
          boxShadow: `inset 0 0 0 3px ${theme.colors[1]}`,
        },
      },
    },

    // Labels
    label: {
      '& a': {
        display: 'inline-block',
        position: 'relative',
      },
    },

    // File Field
    formControlUpload: {
      width: '100%',
      marginBottom: 30,
      '& input': {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
      },
      '& svg': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        right: 40,
        width: 13,
        height: 13,
        fill: theme.colors[5],
      },
      '& label': {
        display: 'block',
        cursor: 'pointer',
        width: '100%',
        color: theme.getRgba(theme.colors[2], 0.3),
        appearance: 'none',
        padding: '16px 28px',
        fontSize: 16,
        fontFamily: theme.fonts[0],
        border: '2px solid transparent',
        background: theme.getRgba(theme.colors[2], 0.05),
        borderRadius: 10,
        transition: 'all .3s ease-out',
        userSelect: 'none',
        '& span': {
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          verticalAlign: 'top',
          width: '100%',
        },
      },
    },
    formControlIsFill: {
      '& label': {
        color: theme.colors[2],
      },
    },

    // Errors
    formError: {
      '& input': {
        borderColor: '#cc0000',
      },
      '& input[type="checkbox"] + span': {
        '&:before': {
          borderColor: '#cc0000',
        },
      },
      '& .customSelect__control': {
        borderColor: '#cc0000',
      },
      '&$formControlUpload': {
        '& label': {
          borderColor: '#cc0000',
        },
      },
    },

    // Password
    showPassword: {
      position: 'absolute',
      zIndex: 1,
      right: 30,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 20,
      height: 20,
      cursor: 'pointer',
      '& svg': {
        width: '100%',
        height: '100%',
        '& #line': {
          display: 'none',
        },
      },
    },
    passwordIsVisible: {
      '& svg': {
        '& #line': {
          display: 'block',
        },
      },
    },

    // Result Message
    result: {
      marginTop: 20,
      padding: '10px 20px',
      borderRadius: 8,
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
      background: 'var(--background-color)',
      color: theme.colors[1],
      textAlign: 'center',
      '& p': {
        margin: 0,
      },
    },
    visibility: {
      opacity: 1,
    },

    // Error Field
    error: {
      position: 'absolute',
      left: 15,
      bottom: -20,
      fontSize: 10,
      color: '#cc0000',
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
    },

    additionalLink: {
      marginBottom: 40,
      width: '100%',
      fontSize: 14,
      textAlign: 'center',
      '& a': {
        color: theme.colors[2],
        '--color': 'currentColor',
        extend: theme.underlined(true),
      },
    },

    visible: {
      opacity: 1,
    },

    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: 1,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mq.md] = {
    input: {
      fontSize: 16,
    },
    textarea: {
      fontSize: 16,
    },
    // Select
    select: {
      fontSize: 16,
      '& .customSelect__control': {
        fontSize: 16,
        '& .customSelect__menu': {
          fontSize: 16,
        },
      },
    },
    checkbox: {
      fontSize: 16,
    },
    additionalLink: {
      fontSize: 16,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mq.sm] = {
    formControl: {
      width: '100%',
      '&.half': {
        width: '100%',
      },
    },
  }

  return output
}

export default style
