import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'
import { fonts, typography } from '@/styles/typography'
import grid from '@/styles/grid'
import { imageFit, responsiveRect, underlined } from '@/styles/utility'
import colors, { color } from '@/styles/colors'
import detect from '@/styles/detect'
import easings from '@/styles/easings'
import header from '@/styles/header'
import mq, { media, mediaInverse, sizes } from '@/styles/mq'
import padding from '@/styles/padding'
import zindex from '@/styles/zindex'
import { SITE, CONFIG } from '@/constants'

export const theme = {
  IMAGE_URL: `${SITE}/wp-content/themes/${CONFIG.THEME_NAME}/images/`,
  ...padding,
  color,
  colors,
  detect,
  easings,
  fonts,
  ...grid,
  ...header,
  icon: {
    size: 56,
    sizeSmall: 41,
    sizeDefault: 35,
  },
  imageFit,
  media,
  mediaInverse,
  mq,
  responsiveRect,
  typography,
  underlined,
  zindex,
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
}
