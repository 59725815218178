import { useState, useEffect } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import clsx from 'clsx'
import { Formik } from 'formik'
import { createYupSchema, createInitialValues } from '@/utils/form'
import TextField from '@/components/__commons/Form/Fields/TextField'
import SelectField from '@/components/__commons/Form/Fields/SelectField'
import CheckboxField from '@/components/__commons/Form/Fields/CheckboxField'
import { theme } from '@/styles/style'
import style from './style'

function Form({
  className,
  onSuccessSend,
  isOnSuccessReset,
  onAfterTimeout,
  classes,
  fields,
  sendContactForm,
  saveConfiguration,
  submitLabel,
  hiddenValues,
  inverseColor,
  initialValues: initialValuesFromProps,
}) {
  const [resultMessage, setResultMessage] = useState(null || '')
  const [status, setStatus] = useState('')
  const [formSchema, setFormSchema] = useState({})
  const [initialValues, setInitialValues] = useState({})

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentLanguage } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  useEffect(() => {
    if (fields.length > 0) {
      setFormSchema(createYupSchema(fields, currentLanguage))
      setInitialValues({ ...createInitialValues(fields), ...initialValuesFromProps })
    }
  }, [fields, initialValuesFromProps])

  return fields.length > 0 && (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        enableReinitialize
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const data = { ...values, ...hiddenValues }

          saveConfiguration(data.email)
            .then((response) => {
              if (response?.data?.id) {
                const result = sendContactForm(
                  {
                    ...data,
                    id: response?.data?.id,
                  },
                  false,
                )

                result
                  .then((res) => {
                    setResultMessage(res.data?.message)
                    setStatus(res.data?.status === 'mail_sent' ? 'success' : 'error')
                    setSubmitting(false)
                    return res
                  })
                  .then((res) => {
                    setTimeout(() => {
                      if (isOnSuccessReset) resetForm()
                      if (res.data?.status === 'mail_sent') onSuccessSend(res)
                      onAfterTimeout()
                      setResultMessage('')
                      setStatus('')
                    }, 3000)
                  })
              }
            })
            .catch((err) => {
              setResultMessage(err?.message)
              setStatus('error')
              setTimeout(() => {
                resetForm()
                setResultMessage('')
                setStatus('')
              }, 2000)
            })
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, submitCount, setFieldValue }) => {
          return (
            <form
              className={clsx({
                [classes.root]: true,
                [classes.rootInverse]: inverseColor,
                [classes.formDisabled]: isSubmitting || resultMessage !== '',
                [className]: className,
              })}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {fields.map((field) => {
                if (field.type === 'text' || field.type === 'tel' || field.type === 'email' || field.type === 'url' || field.type === 'number' || field.type === 'date' || field.type === 'password' || field.type === 'confirm_password') {
                  return (
                    <TextField
                      className={field.class}
                      key={field.name}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      placeholder={`${field.label} ${field.required ? '*' : ''}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      disabled={field.disabled}
                    />
                  )
                }
                if (field.type === 'checkbox') {
                  return (
                    <CheckboxField
                      key={field.name}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                    />
                  )
                }
                if (field.type === 'select') {
                  return (
                    <SelectField
                      key={field.name}
                      label={field.label}
                      name={field.name}
                      required={field.required}
                      options={field.options}
                      includeBlank={field.include_blank}
                      onBlur={handleBlur}
                      value={values[field.name]}
                      error={errors[field.name]}
                      touched={touched[field.name]}
                      submitCount={submitCount}
                      setFieldValue={setFieldValue}
                      className={field.class}
                      conditional={field.conditional}
                      values={values}
                    />
                  )
                }
                return null
              })}
              <button
                className={clsx({
                  [classes.submit]: true,
                  TextField__submit: true,
                })}
                data-layer="submit-login"
                disabled={isSubmitting}
              >
                {submitLabel || 'submit'}
              </button>
            </form>
          )
        }}
      </Formik>
      <div
        className={clsx({
          [classes.result]: true,
          [classes.visibility]: resultMessage !== '',
        })}
        style={{ '--background-color': theme.color[status] }}
      >
        <p dangerouslySetInnerHTML={{ __html: resultMessage }} />
      </div>
    </>
  )
}

Form.defaultProps = {
  onSuccessSend: () => {},
  onAfterTimeout: () => {},
  hiddenValues: {},
  inverseColor: false,
  initialValues: {},
  disabled: false,
  isOnSuccessReset: false,
}

export default injectSheet(style)(Form)
