import { motion } from 'framer-motion'
import useLenisScroll from '@/hooks/useLenisScroll'

function MountTransition({
  children,
  duration = 0.2,
  ease = 'easeOut',
}) {
  useLenisScroll()

  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration, ease }}
    >
      {children}
    </motion.div>
  )
}

export default MountTransition
