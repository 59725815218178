export default (theme) => ({
  root: {
    display: 'inline-block',
    overflow: 'hidden',
  },
  logo: {
    display: 'flex',
    position: 'relative',
    textDecoration: 'none',
    pointerEvents: 'auto',
    height: 15,
  },
  svg: {
    position: 'relative',
    zIndex: 1,
    top: 0,
    left: 0,
    width: 76,
    height: 15,
    fill: theme.colors[2],
    '&.onlyMobile': {
      display: 'none',
    },
  },
})
