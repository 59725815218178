import {
  OPEN_LAYER,
  CLOSE_LAYER,
  CLOSE_ALL_LAYER,
  CREATE_LAYER_MODEL,
  PUSH_PROJECT,
  RESET_PROJECT,
} from '@/actions/types'

const getLayerModel = ({ id }) => ({
  id,
  isOpen: false,
})

const initialState = {
  layers: [],
  layerIsOpen: false,
  currentProject: '',
}

export default (state = initialState, action) => {
  if (typeof action === 'undefined') return state

  switch (action.type) {
    case CREATE_LAYER_MODEL: {
      return {
        ...state,
        layers: [
          ...state.layers,
          getLayerModel({ id: action.payload }),
        ],
      }
    }

    case PUSH_PROJECT: {
      return {
        ...state,
        currentProject: action.payload,
      }
    }

    case RESET_PROJECT: {
      return {
        ...state,
        currentProject: '',
      }
    }

    case OPEN_LAYER: {
      return {
        ...state,
        layerIsOpen: true,
        layers: state.layers.map((l) => (l.id === action.payload.id
          ? ({ ...l,
            ...action.payload,
            isOpen: true,
          })
          : l)),
      }
    }

    case CLOSE_LAYER: {
      const newLayers = state.layers.map((l) => (l.id === action.payload.id
        ? ({ ...l,
          ...action.payload,
          isOpen: false,
        })
        : l))
      return {
        ...state,
        layerIsOpen: newLayers.some((l) => l.isOpen),
        layers: newLayers,
      }
    }

    case CLOSE_ALL_LAYER: {
      return {
        ...state,
        layerIsOpen: false,
        layers: state.layers.map((l) => ({ ...l,
          ...action.payload,
          isOpen: false,
        })),
      }
    }
    default:
      return state
  }
}
