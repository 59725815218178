import * as types from '../actions/types'

const initialState = {
  status: false,
  key: 0,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MEDIA_QUERIES_SET:
      state = {
        ...state,
        status: action.setQuery,
      }
      break
    case types.MEDIA_QUERIES_SET_KEY:
      state = {
        ...state,
        key: action.setQueryKey,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}

export default reducer
