import clsx from 'clsx'
import { useStyles } from './style'

function PageTransition({
  className,
  isLoading,
}) {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.visible]: isLoading,
        [className]: className,
      })}
    >
      <div className={classes.spinner}>
        <svg
          viewBox="0 0 50 50"
          className={classes.spinnerLoader}
        >
          <circle
            className={classes.spinnerPath}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth={4}
          />
        </svg>
      </div>
    </div>
  )
}

export default PageTransition
