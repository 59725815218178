export default (theme) => ({
  scrollbar: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: theme.zindex.scrollbar,
    padding: 0,
    [theme.mediaInverse.sm]: {
      display: 'none !important',
    },
  },
  inner: {
    height: '100%',
    position: 'relative',
  },
  thumb: {
    minHeight: 50,
    width: 4,
    backgroundColor: theme.colors[2],
    position: 'absolute',
    right: 0,
    cursor: 'grab',
  },
})
