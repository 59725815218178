export default (theme) => ({
  root: {
    letterSpacing: '3px',
    marginRight: 9,
    transition: 'opacity .3s ease-out 1s',
    margin: 0,
    padding: '0',
    zIndex: '11',
    fontFamily: theme.fonts[0],
    fontWeight: 400,
    pointerEvents: 'all',
    position: 'relative',
    '& button': {
      letterSpacing: '3px',
      textAlign: 'center',
      paddingLeft: 2,
    },
  },
  popup: {
    position: 'absolute',
    zIndex: 100,
    top: 'calc(100% + 10px)',
    left: 0,
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity .3s ease-out',
  },
  visible: {
    opacity: 1,
    pointerEvents: 'all',
  },
  hide: {
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity .3s ease-out',
  },
  item: {
    display: 'inline-block',
    margin: '0 15px 0 0',
    padding: 0,
    listStyle: 'none',
    boxSizing: 'border-box',
    '& > a,& > div,& > button': {
      display: 'block',
      width: theme.header.burger.height,
      height: theme.header.burger.height,
      lineHeight: `calc(${theme.header.burger.height} + 2px)`,
      borderRadius: '100%',
      textAlign: 'center',
      fontFamily: theme.fonts[0],
      fontSize: 10,
      margin: 0,
      padding: 0,
      textDecoration: 'none',
      textTransform: 'uppercase',
      background: 'rgba(227, 228, 230, 0.5)',
      color: theme.colors[4],
      transition: 'all .3s ease-in-out',
      cursor: 'pointer',
      letterSpacing: '3px',
      paddingLeft: 2,
    },
    '&:hover, &.lang-active': {
      '& > a,& > div,& > button': {
        background: theme.colors[4],
        color: theme.colors[3],
      },
    },
  },
})
